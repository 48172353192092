export default function accordionReveal(event) {
  event.preventDefault();
  var currentLinkWasActive = $(this).hasClass('is-accordion-active');

  // Hide all links' children and remove all active statuses
  // $('.nav-main__list > .nav-main__list-item').children('.nav-main__sub-list').slideUp();
  $(this).removeClass('is-accordion-active');

  $(this).removeClass('accordion__open');
  $(this).addClass('accordion__closed');

  $(this).siblings('.accordion__body').removeClass('slide-down');

  // Activate the current link, if it wasn't previously active
  if (!currentLinkWasActive) {
    $(this).addClass('is-accordion-active');
    $(this).addClass('accordion__open');
    $(this).removeClass('accordion__closed');

    // $(this).siblings('.accordion__body-list').slideDown();
    $(this).siblings('.accordion__body').addClass('slide-down');
  };
};