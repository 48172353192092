export default function play(event) {

	$('#play').click(function() {
	    $('#video, #overlay').fadeIn('slow');
	    $('#video').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/uqXQ_AzpjLc?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});



	$('#local-control').click(function() {
	    $('#video2, #overlay').fadeIn('slow');
	    $('#video2').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/brqaOC3rok8?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});

		$('#local-control').click(function() {
	    $('#video2, #overlay').fadeIn('slow');
	    $('#video2').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/brqaOC3rok8?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});


		$('#reinvestment').click(function() {
	    $('#video2, #overlay').fadeIn('slow');
	    $('#video2').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/k4NjPUnffiI?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});	


		$('#reliability').click(function() {
	    $('#video2, #overlay').fadeIn('slow');
	    $('#video2').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/NNT7v-ONdxI?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});

		$('#community').click(function() {
	    $('#video2, #overlay').fadeIn('slow');
	    $('#video2').html('<iframe height=100% width=100% src=https://www.youtube.com/embed/eOPN_SajYqc?&modestbranding=1&autohide=1&showinfo=0&rel=0&autoplay=1 frameborder=0 allowfullscreen></iframe>');
	});

	$(document).mouseup(function (e)
	{
	    if (!$('#video').is(e.target))
	    {
	        $('#video, #overlay').fadeOut('slow');
	        $('#video').html('');
	    }
	});

	$(document).mouseup(function (e)
	{
	    if (!$('#video2').is(e.target))
	    {
	        $('#video2, #overlay').fadeOut('slow');
	        $('#video2').html('');
	    }
	});

		$(document).mouseup(function (e)
	{
	    if (!$('#video3').is(e.target))
	    {
	        $('#video3, #overlay').fadeOut('slow');
	        $('#video3').html('');
	    }
	});

			$(document).mouseup(function (e)
	{
	    if (!$('#video4').is(e.target))
	    {
	        $('#video4, #overlay').fadeOut('slow');
	        $('#video4').html('');
	    }
	});


			$(document).mouseup(function (e)
	{
	    if (!$('#video5').is(e.target))
	    {
	        $('#video5, #overlay').fadeOut('slow');
	        $('#video5').html('');
	    }
	});


};
